<template>
  <div>
    <v-row v-if="store.app.onMobile" class="ma-0 mb-1 align-center">
      <v-rating :value="review.rating" :readonly="true" background-color="grey" color="warning" dense size="32" :half-increments="true"/>
      <v-spacer/>
      <p class="text--secondary mb-0 text-body-2">{{ getRelativeTimeString(Date.parse(review.importDTStamp)) }}</p>
    </v-row>
    <v-row class="pa-0 ma-0 w-full">
      <v-col cols="1" class="pa-0 d-none d-md-block" style="max-width: 55px">
        <Avatar :img="review.userID.picture" :showBg="true" :size="store.app.onMobile ? 28 : 40"/>
      </v-col>
      <v-col cols="12" class="col-md-11 pl-1 pr-0 px-sm-0 py-0">
        <v-row class="align-center ma-0">
          <h4>@{{ review.userID.username }}</h4>
          <v-icon v-if="review.userID._id === 'course-eval'" color="accent" size="18" class="ml-1">mdi-check-decagram</v-icon>
          <div v-if="!store.app.onMobile">
            <v-rating :value="review.rating" :readonly="true" background-color="grey" color="warning" dense class="mx-2" :half-increments="true"/>
          </div>
          <v-spacer v-if="store.app.onMobile"/>
          <p v-if="!store.app.onMobile" class="text--secondary mb-0 text-body-2">{{ getRelativeTimeString(Date.parse(review.importDTStamp)) }}</p>
          <v-spacer v-if="!store.app.onMobile"/>
          <div v-if="review.recommend >= 4 && !store.app.onMobile && review.userID.username !== 'UofTCourseEval'" class="d-flex ml-3">
            <v-icon color="accent" class="mr-1" size="20">mdi-check-circle</v-icon>
            <p v-if="!store.app.onMobile" class="mb-0">Yes, I recommend this course</p>
          </div>
        </v-row>
        <v-row class="ma-0">
          <p v-if="review.year || review.professor" class="mb-0 text--secondary text-body-2 mr-1">taught</p>
          <p v-if="review.year" class="mb-0 text--secondary text-body-2 mr-1">in {{ review.year }}</p>
          <div v-if="review.professor" class="d-flex flex-row">
            <p class="text--secondary text-body-2 text-decoration-none mr-1 mb-0">by</p>
            <a class="text-decoration-underline text-body-2" @click="viewProfessor(review.professor)">{{ review.professor.displayName }}</a>
          </div>
        </v-row>
        <div v-if="review.recommend >= 4 && store.app.onMobile && review.userID.username !== 'UofTCourseEval'" class="d-flex mt-3">
          <v-icon color="accent" class="mr-1" size="20">mdi-check-circle</v-icon>
          <p class="mb-0">Yes, I recommend this course</p>
        </div>
        <v-row class="ma-0 pt-1">
          <v-col v-for="(field, idx) in ratingFields" :key="idx" cols="4" md="2" class="pl-0 pr-2 pr-md-6 pb-1" style="max-width: 140px">
            <div>
              <p class="text-capitalize mb-1 text-body-2">{{ field }}</p>
              <div class="rounded text-center" :style="'background-color: var(--v-border-base); max-width: 100px'">
                <h3 class="mx-2 py-2">{{ review[field] }}</h3>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="review.comment" class="mx-0 mt-5">
          <p class="mb-2">{{ review.comment }}</p>
        </v-row>
        <v-row v-if="review.tags.length" class="ma-0 mt-5">
          <p class="accent--text font-italic ml-0 mb-0 mr-3 font-weight-medium" v-for="(tag, tagIdx) in review.tags" :key="tagIdx">#{{ tag }}</p>
        </v-row>
        <v-row v-if="review.userID.username !== 'UofTCourseEval'" class="mx-0 mt-5 mb-0 align-center">
          <p class="text--secondary mb-0 text-body-2 mr-3">Helpful?</p>
          <v-icon v-if="review.liked" @click="likeReview('DELETE')" size="20" color="accent">mdi-thumb-up</v-icon>
          <v-icon v-else @click="likeReview('POST')" size="20">mdi-thumb-up-outline</v-icon>
          <p class="mb-0 ml-1 mr-3 text--secondary text-body-2">{{ review.likes > 0 ? review.likes : '' }}</p>
          <v-icon v-if="review.liked === false" @click="dislikeReview('DELETE')" size="20" color="accent">mdi-thumb-down</v-icon>
          <v-icon v-else @click="dislikeReview('POST')" size="20">mdi-thumb-down-outline</v-icon>
          <p class="mb-0 mx-1 text--secondary text-body-2">{{ review.dislikes > 0 ? review.dislikes : '' }}</p>
          <v-divider v-if="store.user.userInfo" vertical class="mx-4"/>
          <p v-if="store.user.userInfo && !review.reported" class="text--secondary ml-1 mb-0 text-body-2 pointer" @click="report.showModal = true">Report</p>
          <p v-else-if="store.user.userInfo && review.reported" class="ml-1 mb-0 text-body-2 error--text font-italic"><v-icon color="error" class="mr-1">mdi-flag</v-icon>Reported</p>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="report.showModal" max-width="400px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px">
        <v-card-title class="pt-6 pb-0 text-h5 font-weight-bold accent--text">Report Review</v-card-title>
        <v-card-text class="pb-0">
          <v-radio-group v-model="report.reason">
            <v-radio v-for="reason in report.options" :key="reason" :label="reason" :value="reason" color="accent"/>
          </v-radio-group>
          <v-textarea v-if="report.reason === report.options[5]" v-model="report.comment" flat filled no-resize
                      height="85" class="grow-0" counter solo-inverted background-color="border"
                      placeholder="The review was ... (100 character max)."></v-textarea>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer/>
          <v-btn color="accent" text @click="resetReport">Cancel</v-btn>
          <v-btn color="accent" class="mx-2 px-3" depressed :disabled="!enableReportSubmission" @click="reportReview">Report</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="needAuthentication" @click:outside="store.errors.resetAuthError()" max-width="425px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px; overflow-y: auto" height="650px">
        <SessionModal @loggedIn="needAuthentication = false" css="my-10 pt-8 px-0 overflow-hidden"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Avatar from '@/components/shared/Avatar.vue'
import { getRelativeTimeString } from '@/utils/shared/helpers'
import SessionModal from '@/components/SessionModal.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'ReviewItem',
  components: { SessionModal, Avatar },
  props: {
    review: { type: Object, required: true }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    report: {
      showModal: false,
      showOther: false,
      reason: null,
      comment: null,
      options: [
        'Hate speech or graphic violence',
        'Contains profanity',
        'Harassment or bullying',
        'Suicide or self injury',
        'Misinformation',
        'Other (Please specify)'
      ]
    },
    featuredRatingFields: ['recommend', 'workload'],
    ratingFields: ['workload', 'understanding', 'evaluations', 'environment', 'application', 'stimulating'],
    needAuthentication: false,
    eventQueue: { likeReview: null, dislikeReview: null }
  }),
  watch: {
    needAuthentication: function (newValue) {
      if (!newValue && this.store.user.userInfo) {
        if (this.eventQueue.likeReview) {
          this.likeReview(this.eventQueue.likeReview)
          this.eventQueue.likeReview = null
        } else if (this.eventQueue.dislikeReview) {
          this.dislikeReview(this.eventQueue.dislikeReview)
          this.eventQueue.dislikeReview = null
        }
      }
    }
  },
  computed: {
    enableReportSubmission () {
      if (this.report.reason === this.report.options[5]) {
        return this.report.comment
      } else {
        return !!this.report.reason
      }
    }
  },
  methods: {
    getRelativeTimeString,
    likeReview (method) {
      if (this.store.user.userInfo) {
        const request = {
          query: 'mutation likeReview($reviewId: String!, $method:LikeMethod!) { likeReview(reviewId: $reviewId, method: $method) { status, message } }',
          variables: {
            reviewId: this.review._id,
            method: method
          },
          operationName: 'likeReview'
        }
        this.sendRequest(request)
      } else {
        this.eventQueue.likeReview = method
        this.needAuthentication = true
      }
    },
    dislikeReview (method) {
      if (this.store.user.userInfo) {
        const request = {
          query: 'mutation dislikeReview($reviewId: String!, $method:LikeMethod!) { dislikeReview(reviewId: $reviewId, method: $method) { status, message } }',
          variables: {
            reviewId: this.review._id,
            method: method
          },
          operationName: 'dislikeReview'
        }

        this.sendRequest(request)
      } else {
        this.eventQueue.dislikeReview = method
        this.needAuthentication = true
      }
    },
    async reportReview () {
      const q = {
        query: 'mutation reportReview($reviewId: String!, $reasonType: String!, $comment: String) { ' +
          'reportReview(reviewId: $reviewId, reasonType: $reasonType, comment: $comment) { status, message } }',
        variables: {
          reviewId: this.review._id,
          reasonType: this.report.reason,
          comment: this.report.comment
        },
        operationName: 'reportReview'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data.reportReview.status) {
              this.$toast.info(graphQlRes.data.reportReview.message)
              this.$emit('update', this.review._id)
              this.$gtag.event('review_reported', { value: 1 })
            } else {
              this.$toast.warning(graphQlRes.data.reportReview.message)
            }
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
          this.resetReport()
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async sendRequest (request) {
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data[request.operationName].status) {
              this.$toast.info(graphQlRes.data[request.operationName].message)
              this.$emit('update', this.review._id)
              this.$gtag.event(request.operationName, { value: 1 })
            } else {
              this.$toast.warning(graphQlRes.data[request.operationName].message)
            }
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
          this.resetReport()
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    resetReport () {
      this.report.showModal = false
      this.report.reason = null
      this.report.comment = null
    },
    viewProfessor (prof) {
      this.store.data.selectedProf = prof
      this.$router.push({ path: '/professors', query: { p: prof._id } })
    }
  }
}
</script>

<style scoped>
  .v-chip--disabled {
    opacity: 1;
  }

</style>
