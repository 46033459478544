<template>
  <v-progress-linear :value="value * 20" :height="height" rounded color="accent" background-color="border"/>
</template>

<script>
export default {
  name: 'RatingBar',
  props: {
    value: { type: Number, default: 0 },
    height: { type: Number, default: 12 }
  },
  methods: {
    ratingColour (rating) {
      if (rating >= 4) return 'accent'
      else if (rating >= 3) return 'warning'
      else if (rating > 0) return 'error'
      else return 'secondary'
    }
  }
}
</script>

<style scoped>

</style>
