<template>
  <v-container class="contentContainer pb-0">
    <v-row class="ma-0 mb-6 align-center">
      <slot/>
      <div v-if="metadata.numReviews > 0" class="d-flex flex-row grow">
        <DropMenu icon="mdi-sort-variant" :options="state.sort.options"
                  @optionUpdate="sortReviews($event)" :default-active="state.sort.current"/>
        <v-spacer/>
        <DropMenu v-if="!store.app.onMobile" icon="mdi-filter-variant" :options="state.filter.options" :left="true" :right="false"
                  :chevron="!store.app.onMobile" @optionUpdate="filterReviews($event)" :default-active="state.filter.current"/>
      </div>
    </v-row>
    <div v-for="(review, idx) in reviews" :key="review._id">
      <review-item :review="review" class="mb-7" @update="handleUpdate($event)" @flag="removeFlaggedReview($event)"/>
      <v-divider v-if="idx + 1 < reviews.length" class="my-6"/>
    </div>
    <div v-if="metadata.numReviews < 1 && !loading" class="d-block flex-col w-full text-center">
      <v-icon class="my-6" size="80" color="accent">mdi-chat-question-outline</v-icon>
      <p class="mb-1">Be the first to leave a review.</p>
      <p class="text--secondary">It's quiet... too quiet...</p>
    </div>
    <div v-else-if="metadata.numReviews > 0 && meta.count < 1 && !loading" class="d-block flex-col w-full text-center">
      <v-icon class="my-6" size="80" color="accent">mdi-chat-question-outline</v-icon>
      <p class="mb-1 text--secondary">No reviews found</p>
    </div>
    <v-row v-else justify="center" class="mt-12 pt-4">
      <p v-if="!loading" class="text--secondary">
        Showing {{ Math.min(((state.skip + 1) * 10), meta.count) }} of {{ meta.count }} reviews
      </p>
    </v-row>
    <v-row v-if="state.hasMore" justify="center" class="mb-4">
      <p v-if="!loading" class="accent--text font-weight-medium mb-0 pointer" @click="getNext">Load more</p>
      <v-row v-else class="pa-0 mt-10 justify-center">
        <v-progress-circular indeterminate :size="20" :width="3" color="accent" class="mr-2"/>
        <p class="text--secondary">Loading more...</p>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import ReviewItem from '@/components/review/ReviewItem.vue'
import DropMenu from '@/components/DropMenu.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'Reviews',
  components: { DropMenu, ReviewItem },
  props: {
    course: { type: String, required: null },
    metadata: { type: Object, required: true }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    loading: true,
    reviews: [],
    meta: { count: 0 },
    state: {
      skip: 0,
      sort: {
        current: 0,
        options: [
          { label: 'Most recent', value: { importDTStamp: -1 } },
          { label: 'Top reviews', value: { likes: -1 } }
        ]
      },
      filter: {
        current: 0,
        options: [
          { label: 'All reviews', value: 'default' },
          { label: 'Has comments/tags', value: 'commentsAndTags' }
        ]
      },
      hasMore: true
    }
  }),
  async mounted () {
    this.getReviews(false)
  },
  watch: {
    course: function (newValue) { if (newValue) { this.getReviews(true) } }
  },
  computed: {
    numReviews () {
      return 1
    }
  },
  methods: {
    getReviews (reset) {
      this.loading = true
      if (reset) {
        this.reviews = []
        this.state.skip = 0
        this.state.filter.current = 0
        this.state.hasMore = true
      }
      const q = {
        query: 'query getReviews($course: String!, $filter: String, $skip: Float!, $sort: JSON!) { ' +
          'getReviews(course: $course, filter: $filter, skip: $skip, sort: $sort) { reviews, meta } }',
        variables: {
          course: this.course,
          filter: this.state.filter.options[this.state.filter.current].value,
          skip: this.state.skip,
          sort: this.state.sort.options[this.state.sort.current].value
        },
        operationName: 'getReviews'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.reviews = this.reviews.concat(graphQlRes.data.getReviews.reviews)
            this.meta = graphQlRes.data.getReviews.meta
            this.state.hasMore = graphQlRes.data.getReviews.reviews.length === 10
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
          this.loading = false
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    getNext () {
      this.state.skip += 1
      this.getReviews(false)
    },
    filterReviews (newFilter) {
      if (this.state.filter.current !== newFilter) {
        this.state.filter.current = newFilter
        this.reviews = []
        this.state.skip = 0
        this.state.hasMore = true
        this.getReviews(false)
      }
    },
    sortReviews (newSort) {
      if (this.state.sort.current !== newSort) {
        this.state.sort.current = newSort
        this.reviews = []
        this.state.skip = 0
        this.state.hasMore = true
        this.getReviews(false)
      }
    },
    async handleUpdate (reviewId) {
      const q = {
        query: 'query getReview($reviewId: String!) { getReview(reviewId: $reviewId) { reviews } }',
        variables: { reviewId: reviewId },
        operationName: 'getReview'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            const reviewIdx = this.reviews.findIndex((entry) => { return entry._id === reviewId })
            if (reviewIdx > -1) {
              this.reviews[reviewIdx] = graphQlRes.data.getReview.reviews[0]
              this.$forceUpdate()
            }
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    removeFlaggedReview (review) {
      const reviewIdx = this.reviews.findIndex((entry) => { return entry._id === review._id })
      if (reviewIdx > -1) {
        this.reviews.splice(reviewIdx, 1)
        this.meta.count -= 1
        this.$emit('update')
      }
    }
  }
}
</script>

<style scoped>

</style>
