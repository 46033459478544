<template>
    <v-container class="contentContainer">
      <!-- PAGE BANNER - Course Title -->
      <v-row class="my-0 pageHeader" justify="center">
        <v-col class="pa-0">
          <v-row class="pt-2 ma-0 align-center">
            <v-skeleton-loader v-if="!(courseDetails.data && courseDetails.data.code)" type="text" width="200"/>
            <p class="pageHeading align-center pb-0 text-h4">{{ courseDetails.data.code }}</p>
            <v-icon v-if="courseDetails.data.taken" color="success" class="ml-1">mdi-check-decagram</v-icon>
            <v-icon v-else-if="courseDetails.data.saved" color="error" size="32" class="ml-1">mdi-bookmark</v-icon>
            <QuickStats v-if="!store.app.onMobile" :stats="courseDetails.data" css="ml-5" icon-size="26"/>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-skeleton-loader v-if="!(courseDetails.data && courseDetails.data.course)" type="text" width="300" class="pt-3"/>
            <p v-if="courseDetails.data.course.slice(9).trim().length <= 85" class="text-body-1 mb-0 text-sm-h6 text-md-h6 text-lg-h6">
              {{ courseDetails.data.course.slice(9).trim() }}
            </p>
            <v-tooltip v-else bottom color="tooltip">
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on" class="text-body-1 mb-0 text-sm-h6 text-md-h5 text-lg-h5"
                   style="width: fit-content">{{ courseDetails.data.course.slice(9).trim().slice(0, 85) }}...
                </p>
              </template>
              <span class="font-weight-medium">{{ courseDetails.data.course.slice(9).trim() }}</span>
            </v-tooltip>
            <v-spacer v-if="!store.app.onMobile"/>
            <QuickStats v-if="store.app.onMobile" :stats="courseDetails.data" spacing="4"
                        css="py-2 pr-3 d-flex align-center" style="min-width: 75%"/>
            <v-spacer/>
            <CourseToggle v-if="store.user.userInfo" @courseToggle="refreshInfo" :saved="courseDetails.data.saved"
                          :already-taken="courseDetails.data.taken" :course="courseDetails.data" :large="!store.app.onMobile"/>
            <ShareButton v-if="!store.app.onMobile || !store.user.userInfo" :value="courseDetails.data.code"/>
          </v-row>
        </v-col>
      </v-row>
      <!-- MAIN CONTENT AREA - Content Section -->
      <v-row class="my-0">
        <v-col class="mainCol dataCol pr-3 pr-sm-6 pt-5 mt-1">
          <!-- DESCRIPTION AREA - Course Details Section -->
          <InfoDisplay :course-info="courseDetails.data" :auto-size="false" :show-tree="true" :dynamic-codes="true" @updateInfo="getCourseData()"/>
          <v-spacer class="py-4 py-sm-8"/>
          <!-- COURSE HISTORY AREA - Course & Professor History Section -->
          <HistoryChart :history-data="historyData" type="course" @updateSemester="getCourseHistory($event)">
            <p class="text-h4 pr-4 mb-0">{{ store.app.onMobile ? 'History' : 'Drop Rates' }}</p>
          </HistoryChart>
          <v-row v-if="$vuetify.breakpoint.mdAndDown" class="py-12 py-sm-8">
            <v-col>
              <v-row class="align-center justify-space-between">
                <p v-if="!store.app.onMobile" class="text-h4 pr-4 mb-0">Course Reviews</p>
                <p v-else class="text-h4 pr-4 mb-0">Reviews</p>
                <ReviewModal :course="courseDetails.data" type="course" @update="refreshInfo"/>
              </v-row>
              <v-row class="px-1">
                <v-col class="col-md-4 text-center px-0" cols="12" align-self="center">
                  <div class="mb-5">
                    <v-row class="justify-center mb-0 mt-5 mt-sm-0">
                      <v-icon color="warning" x-large class="mr-3">mdi-star</v-icon>
                      <p class="mb-0 mr-2 text-h2 font-weight-bold">
                        {{ formatRating(courseDetails.data.numReviews > 0 ? courseDetails.data.rating : -1, true) }}
                      </p>
                      <p class="text-body-1 text--secondary align-self-end mb-1">/ 5</p>
                    </v-row>
                    <p class="accent--text text-decoration-underline pointer text-center mb-0 mb-sm-5"
                       @click="$scrollTo('#reviewSection', { duration: 350, offset: -50 })">
                      {{ courseDetails.data.numReviews }} reviews
                    </p>
                  </div>
                  <v-row class="ma-0 d-none d-md-block"><v-divider class="mx-12"/></v-row>
                  <v-row class="text-center mt-5">
                    <v-col>
                      <h2 v-if="courseDetails.data.numReviews > 0">{{ formatRating(courseDetails.data.recommend, false) }}</h2>
                      <h2 v-else>---</h2>
                      <p class="text--secondary mb-0">Recommend</p>
                    </v-col>
                    <v-divider vertical class="my-4"/>
                    <v-col>
                      <h2 v-if="courseDetails.data.numReviews > 0">{{ formatRating(courseDetails.data.workload, false) }}</h2>
                      <h2 v-else>---</h2>
                      <p class="text--secondary mb-0">Workload</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider class="d-none d-md-block" vertical/>
                <v-col class="col-md-8 justify-center pl-md-5 px-0" cols="12">
                  <v-divider v-if="$vuetify.breakpoint.smAndDown" class="mb-3"/>
                  <v-row>
                    <v-col class="col-md-6 px-0" cols="12" align-self="center">
                      <v-row v-for="(rating, idx) in ratingFields" :key="idx" class="px-3 mx-1 my-0 align-center">
                        <v-col cols="5" class="mx-0 mt-2 mb-1 pl-0">
                          <v-tooltip bottom color="tooltip" max-width="225px">
                            <template v-slot:activator="{ on, attrs }">
                              <p v-bind="attrs" v-on="on" class="mb-0 text--secondary text-capitalize overflow-ellipsis">{{ rating.label }}</p>
                            </template>
                            <span class="font-weight-medium">{{ rating.text }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="1" class="font-weight-medium px-0 text-center">
                          {{ formatRating(courseDetails.data.numReviews > 0 ? courseDetails.data[rating.label] : -1, false) }}
                        </v-col>
                        <v-col cols="6" class="pr-0">
                          <rating-bar :value="courseDetails.data[rating.label]"/>
                        </v-col>
                      </v-row>
                      <v-divider class="d-block d-md-none mt-3 mx-3"/>
                    </v-col>
                    <v-col class="col-md-6 px-0 px-sm-5 mb-0 d-block d-md-flex align-center" cols="12">
                      <p class="text-h5 mx-5 mx-sm-0 d-block d-md-none">Grade Distribution</p>
                      <apexchart v-if="ratingData.grades.data.length || store.app.onDesktop" type="donut"
                                 :options="gradeChart" :series="ratingData.grades.data"/>
                      <p v-else class="text--secondary mb-0 pa-1 text-center">No grades available</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mt-8 mx-0 d-block">
                <v-row class="mx-0 pb-2">
                  <p class="text-h5 mx-0 pr-5 mx-sm-0 d-block d-md-none">Course Tags</p>
                  <v-spacer/>
                  <p v-if="overflowing" @click="tagView = 1 - tagView" class="my-1 hover accent--text">
                    {{ tagView ? 'View all' : 'Minimize' }}
                  </p>
                </v-row>
                <v-slide-group v-show="ratingData.tags.length > 0 && tagView" ref="tagSlides" show-arrows>
                  <v-slide-item v-for="tag in ratingData.tags" :key="tag.tag">
                    <v-chip disabled pill class="ma-2 font-weight-bold white--text" color="accent">
                      {{ tag.tag }} {{ tag.count > 1 ? '(' + tag.count + ')' : ''}}
                    </v-chip>
                  </v-slide-item>
                </v-slide-group>
                <v-chip-group v-if="ratingData.tags.length > 0 && !tagView" class="py-0" column>
                  <v-chip v-for="tag in ratingData.tags" :key="tag.tag" disabled pill class="ma-1 font-weight-bold white--text" color="accent">
                    {{ tag.tag }} {{ tag.count > 1 ? '(' + tag.count + ')' : ''}}
                  </v-chip>
                </v-chip-group>
                <p v-if="ratingData.tags.length < 1" class="text--secondary mb-0 pa-1 text-center">No tags available</p>
              </v-row>
            </v-col>
          </v-row>
          <v-spacer class="py-0 py-sm-4"/>
          <!-- TIMETABLE AREA - Course Timetable Section -->
          <CourseOfferings :course="$route.query.c" prof-links enable-selection show-restrictions/>
          <v-row id="reviewSection">
            <reviews class="pt-10 px-0" ref="courseReviews" @update="getCourseData" :course="$route.query.c"
                     :metadata="courseDetails.data" style="padding:32px -12px">
              <p class="text-h4 mb-0 mr-3">Reviews</p>
            </reviews>
          </v-row>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" class="secondaryCol">
          <v-card outlined rounded class="pa-6" min-width="360" style="background-color: var(--v-background-base)">
            <v-col class="text-center" align-self="center">
              <v-row class="align-center mb-0">
                <p v-if="!store.app.onMobile" class="text-h5 pr-2 mb-0">Review Overview</p>
                <p v-else class="text-h4 pr-2 mb-0">Ratings</p>
                <ReviewModal v-if="store.app.onMobile" :course="courseDetails.data" type="course" @update="refreshInfo"/>
              </v-row>
              <div class="mt-8 mb-5">
                <v-row class="justify-center mb-0 mt-5 mt-sm-0">
                  <v-icon color="warning" x-large class="mr-3">mdi-star</v-icon>
                  <p class="mb-0 mr-2 text-h2 font-weight-bold">
                    {{ formatRating(courseDetails.data.numReviews > 0 ? courseDetails.data.rating : -1, true) }}
                  </p>
                  <p class="text-body-1 text--secondary align-self-end mb-1">/ 5</p>
                </v-row>
                <p class="accent--text text-decoration-underline pointer text-center mb-0 mb-sm-5"
                   @click="$scrollTo('#reviewSection', { duration: 350, offset: -50 })">
                  {{ courseDetails.data.numReviews }} reviews
                </p>
                <ReviewModal v-if="!store.app.onMobile" :course="courseDetails.data" type="course" @update="refreshInfo"/>
              </div>
            </v-col>
            <v-divider class="d-none d-md-block"/>

            <v-row class="text-center my-1">
              <v-col>
                <h2 v-if="courseDetails.data.numReviews > 0">{{ formatRating(courseDetails.data.recommend, false) }}</h2>
                <h2 v-else>---</h2>
                <v-tooltip bottom color="tooltip" max-width="200px">
                  <template v-slot:activator="{ on, attrs }">
                    <p v-bind="attrs" v-on="on" class="text--secondary text-body-2 mb-0">Recommend</p>
                  </template>
                  <span class="font-weight-medium">Would recommend the course. Higher is better!</span>
                </v-tooltip>
              </v-col>
              <v-divider vertical class="my-4"/>
              <v-col>
                <h2 v-if="courseDetails.data.numReviews > 0">{{ formatRating(courseDetails.data.workload, false) }}</h2>
                <h2 v-else>---</h2>
                <v-tooltip bottom color="tooltip" max-width="200px">
                  <template v-slot:activator="{ on, attrs }">
                    <p v-bind="attrs" v-on="on" class="text--secondary text-body-2 mb-0">Workload</p>
                  </template>
                  <span class="font-weight-medium">Overall course workload. Lower is better!</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="mx-0 my-2"><v-divider/></v-row>
            <v-row v-for="(rating, idx) in ratingFields" :key="idx" class="pa-0 mx-1 my-0 align-center">
              <v-col cols="6" class="mx-0 mt-2 mb-1 pl-0 pr-1">
                <v-row class="ma-0 pa-0">
                  <v-tooltip bottom color="tooltip" max-width="225px">
                    <template v-slot:activator="{ on, attrs }">
                      <p v-bind="attrs" v-on="on" class="mb-0 text--secondary text-body-2 text-capitalize overflow-ellipsis">{{ rating.label }}</p>
                    </template>
                    <span class="font-weight-medium">{{ rating.text }}</span>
                  </v-tooltip>
                  <v-spacer/>
                  <p class="font-weight-medium mb-0">
                    {{ formatRating(courseDetails.data.numReviews > 0 ? courseDetails.data[rating.label] : -1, false) }}
                  </p>
                </v-row>
              </v-col>
              <v-col cols="6" class="pr-0">
                <rating-bar :value="courseDetails.data[rating.label]"/>
              </v-col>
            </v-row>
            <v-divider class="mx-0 my-3"/>
            <p class="text-h5 ma-5 mx-sm-0">Grade Distribution</p>
            <apexchart v-if="ratingData.grades.data.length || store.app.onDesktop" type="donut"
                       :options="gradeChart" :series="ratingData.grades.data"/>
            <p v-else class="text--secondary mb-0 pa-1 text-center">No grades available</p>
            <v-row class="mt-8 mx-0 d-block">
              <v-row class="mx-0 pb-2">
                <p class="text-h5 mx-0 pr-5 mx-sm-0">Course Tags</p>
                <v-spacer/>
                <p v-if="overflowing" @click="tagView = 1 - tagView" class="my-1 hover accent--text">
                  {{ tagView ? 'View all' : 'Minimize' }}
                </p>
              </v-row>
              <v-slide-group v-show="ratingData.tags.length > 0 && tagView" ref="tagSlides" show-arrows>
                <v-slide-item v-for="tag in ratingData.tags" :key="tag.tag">
                  <v-chip disabled pill class="ma-2 font-weight-bold white--text" color="accent">
                    {{ tag.tag }} {{ tag.count > 1 ? '(' + tag.count + ')' : ''}}
                  </v-chip>
                </v-slide-item>
              </v-slide-group>
              <v-chip-group v-if="ratingData.tags.length > 0 && !tagView" class="py-0" column>
                <v-chip v-for="tag in ratingData.tags" :key="tag.tag" disabled pill class="ma-1 font-weight-bold white--text" color="accent">
                  {{ tag.tag }} {{ tag.count > 1 ? '(' + tag.count + ')' : ''}}
                </v-chip>
              </v-chip-group>
              <p v-if="ratingData.tags.length < 1" class="text--secondary text-body-2 mb-0 pa-2 text-center">No tags available</p>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import HistoryChart from '@/components/HistoryChart'
import CourseToggle from '@/components/CourseToggle'
import InfoDisplay from '@/components/InfoDisplay'
import ReviewModal from '@/components/review/ReviewModal.vue'
import ShareButton from '@/components/ShareButton'
import CourseOfferings from '@/components/CourseOfferings'
import QuickStats from '@/components/QuickStats'
import RatingBar from '@/components/review/RatingBar.vue'
import Reviews from '@/components/review/Reviews.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'CourseInfo',
  components: { Reviews, RatingBar, HistoryChart, CourseToggle, InfoDisplay, ReviewModal, ShareButton, CourseOfferings, QuickStats },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    ratingScreen: 0,
    historyScreen: 0,
    tagView: 1,
    overflowing: null,
    courseDetails: {
      course: null,
      data: { course: '', bird: 0, drop: 0, saved: false, taken: false },
      timetable: { controls: false, lec: [], tut: [] }
    },
    ratingFields: [
      { label: 'understanding', text: 'Provided a deeper understanding of the subject' },
      { label: 'evaluations', text: 'Evaluations improved course understanding' },
      { label: 'environment', text: 'The professor created a good learning environment' },
      { label: 'application', text: 'Evaluations allowed me to demonstrate my knowledge' },
      { label: 'stimulating', text: 'The course was intellectually stimulating' }
    ],
    historyHeaders: [
      { text: 'Offering', value: 'sem' },
      { text: 'Professor(s)', value: 'prof' },
      { text: 'Current', value: 'current' },
      { text: 'Cutoff', value: 'cutoff' },
      { text: 'Capacity', value: 'capacity' },
      { text: 'Drop Rate', value: 'drop' }
    ],
    timetableHeaders: [
      { text: 'Section', align: 'start', value: 'section' },
      { text: 'Day', value: 'times.day' },
      { text: 'Time', value: 'times.time' },
      { text: 'Location', value: 'delivery' },
      { text: 'Enrolment', value: 'enrolment' },
      { text: 'Instructor(s)', value: 'instructor' }
    ],
    timetableHeadersMobile: [0],
    timetableSections: [
      { label: 'lec', value: 'Lecture(s)', slice: 6 },
      { label: 'tut', value: 'Tutorial(s)', slice: 5 }
    ],
    ratingData: { breakdown: [], tags: [], grades: { data: [], labels: [] } },
    timetableFilter: { loaded: false, semesters: [], active: 0 },
    historyData: {
      series: [{ name: '', data: [] }],
      sem: [],
      labels: [],
      details: []
    },
    stateVars: ['courseDetails', 'ratingData', 'timetableFilter', 'historyData']
  }),
  mounted () {
    // Use HistoryData to check prevState since infoDisplay might partially load courseDetails.data
    const prevState = this.store.data.selectedCourse
    if (prevState.historyData && (prevState.courseDetails.course === this.$route.query.c)) {
      this.loadState()
    } else {
      this.store.data.resetSelectedCourse()
      this.getCourseData()
    }
    this.$watch('$refs.tagSlides._data.isOverflowing', function (newValue) {
      this.overflowing = newValue
    })
  },
  beforeRouteEnter (to, from, next) {
    if (to.query.c) {
      next()
    } else {
      next(from.path)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (['/professors', '/tree', '/directory'].includes(to.path)) {
      this.saveState()
    } else {
      if (this.store.data.filterState.filters) {
        this.store.data.resetSelectedFilters()
      }

      this.store.data.resetDirectoryState()
    }
    next()
  },
  watch: {
    '$route.query': function () {
      this.store.data.resetSelectedCourse()
      this.getCourseData()
    }
  },
  computed: {
    gradeChart: function () {
      return {
        chart: { type: 'donut' },
        noData: {
          text: 'No grades available',
          style: { color: this.store.app.darkMode ? '#BCBCBC' : '#747474' }
        },
        labels: this.ratingData.grades.labels,
        fill: { opacity: 1 },
        stroke: { width: 1, colors: undefined },
        yaxis: { show: false },
        legend: {
          position: 'bottom',
          labels: { colors: this.store.app.darkMode ? '#FFFFFF' : '#1E1E1E' }
        },
        theme: {
          monochrome: {
            enabled: true,
            color: this.store.app.darkMode ? '#1976D2' : '#003C85',
            shadeTo: this.store.app.darkMode ? 'dark' : 'light',
            shadeIntensity: 0.8
          }
        }
      }
    },
    footerProps () {
      return {
        showFirstLastPage: !this.store.app.onMobile,
        itemsPerPageText: !this.store.app.onMobile ? 'Courses per page' : ''
      }
    }
  },
  methods: {
    saveState () {
      this.stateVars.forEach((element) => {
        this.store.data.setSelectedCourse(element, this[element])
      })
    },
    loadState () {
      this.stateVars.forEach((element) => {
        this[element] = this.store.data.selectedCourse[element]
      })
    },
    async getCourseData () {
      // Don't need to fetch the details since coming from directory page
      if (this.store.data.selectedCourse.courseDetails.data) {
        this.courseDetails.data = this.store.data.selectedCourse.courseDetails.data
        this.courseDetails.course = this.courseDetails.data.code
      } else {
        this.courseDetails.course = this.$route.query.c
        await this.getDetails()
      }
      // Fetch the remaining data
      await this.getCourseHistory(-1)
      await this.getReviewAggregate()
      await this.getTimetable(-1)
      // GTAG
      this.$gtag.event('info_' + this.courseDetails.course, { value: 1 })
    },
    async getDetails () {
      // Call backend for course details
      const q = {
        query: 'query courseInfo($course: String!) { courseInfo(course: $course) { ' +
          'code, course, description, section, bird, drop, prereq, coreq, preparation, exclusion, limits, breadth, distribution,' +
          'notes, rating, recommend, workload, understanding, evaluations, environment, application, stimulating, numReviews, saved, taken, rated } }',
        variables: { course: this.courseDetails.course },
        operationName: 'courseInfo'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data.courseInfo.length === 0) {
              this.$router.push('/')
              this.$toast.warning('Sorry! We could not find a course matching: ' + this.courseDetails.course)
            } else {
              this.courseDetails.data = graphQlRes.data.courseInfo[0]
            }
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async getCourseHistory (limit) {
      // Need to call backend for course history data
      const q = {
        query: 'query getHistory($filter: JSON!, $limit: Float!) { getHistory(filter: $filter, limit: $limit) { history } }',
        variables: {
          filter: { code: this.courseDetails.course },
          limit: limit > -1 ? limit : 10
        },
        operationName: 'getHistory'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.historyData = graphQlRes.data.getHistory.history
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async getReviewAggregate () {
      const q = {
        query: 'query getReviewAggregate($category: String!, $value: String!) { getReviewAggregate(category: $category, value: $value) { tags, grades } }',
        variables: { category: 'course', value: this.courseDetails.course },
        operationName: 'getReviewAggregate'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.ratingData.tags = graphQlRes.data.getReviewAggregate.tags
            this.ratingData.grades = graphQlRes.data.getReviewAggregate.grades
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async getTimetable (index) {
      // Need to call backend for timetable data
      this.timetableFilter.active = index > -1 ? index : 0
      const q = {
        query: 'query getTimetable($course: String!, $sem: String, $year: String) { ' +
          'getTimetable(course: $course, sem: $sem, year: $year) { timetable, filters } }',
        variables: {
          course: this.courseDetails.course,
          sem: index > -1 ? this.timetableFilter.semesters[index].value.sem : null,
          year: index > -1 ? this.timetableFilter.semesters[index].value.year : null
        },
        operationName: 'getTimetable'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.courseDetails.timetable = graphQlRes.data.getTimetable.timetable
            if (!this.timetableFilter.loaded) this.timetableFilter.semesters = graphQlRes.data.getTimetable.filters
            this.timetableFilter.loaded = true
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async refreshInfo () {
      await this.getDetails()
      await this.getReviewAggregate()
      await this.$refs.courseReviews.getReviews(true)
    },
    formatRating (value, style) {
      return value > -1 ? value.toFixed(1) : style ? 'N/A' : '--.--'
    }
  }
}
</script>

<style scoped>
  >>>.v-data-footer {
    padding: 0;
  }
  >>>.v-data-footer__pagination {
    margin: 0 20px 0 12px;
  }
  >>>.v-data-table__mobile-row {
    align-items: start;
    padding: 6px !important;
  }
  >>>.v-toolbar__content {
    padding-right: 0;
  }
  >>>.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #333232;
  }
  .v-application a {
    font-weight: 510;
    text-decoration: none;
    color: var(--v-accent-base) !important;
  }
  .timetableRow {
    min-width: 100%;
  }
  .v-chip--disabled {
    opacity: 1;
  }
  >>>.apexcharts-tooltip {
    color: #FFFFFF;
  }
  .dataCol {
    overflow-y: auto;
    overflow-x: hidden;
    flex-basis: 0;
    flex-grow: 1;
    margin: 12px;
  }
  .mainCol {
    padding-left: max(calc((100% - 1680px)/2), 12px);
    margin-left: 0;
    margin-right: 0;
  }
  .pageHeader {
    padding: 4px max(calc((100% - 1680px)/2), 1px);
    min-width: 100%;
  }
  .secondaryCol {
    padding-right: max(calc((100% - 1680px)/2), 1px);
    margin-right: 0;
    flex: 0 1 calc(30% + 12px);
    overflow-y: auto;
  }
  >>>.v-slide-group__next {
    min-width: 0;
  }
  >>>.v-slide-group__prev {
    min-width: 0;
  }

</style>
